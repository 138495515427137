import React, {useState, useEffect} from 'react';
import { Link, useLocation } from 'react-router-dom';
import './styles.scss';
import logo from '../../assets/images/logo.svg'
import cross from '../../assets/cross.svg'
import line from '../../assets/line.svg'

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

const NavBar = () => {
  const handleScroll = (event) => {
    const currentScrollPos = window.pageYOffset;
    setScrollPos(currentScrollPos);
  }
  const { width, height } = useWindowDimensions();
  const [scroll, setScrollPos] = useState('')
  useEffect(() => window.addEventListener('scroll', handleScroll), []);
  useEffect(() => window.scroll({
    top: 0,
    behavior: 'instant'
  }), [window.location.href]);
  const location = useLocation();
  const scrollStyles = `
    navbar
    navbar-expand-lg
    navbar-light
    floating
    ${location.pathname ===  '/contacto' && 'contact'}
    ${
      (scroll>=height*0.88 &&
        location.pathname==='/'&&
        width>=1000) &&
        'semi-transparent'
    }`
  const pathName = location.pathname;
  const [toggled, setToggled] = useState(false)
  const [canToggle, setCanToggle] = useState(true)

  useEffect(() => {
    setCanToggle(false)
    window.setTimeout(() => setCanToggle(true), 500);
  }, [toggled])
 // useEffect(() => setToggled(true))
  return (
    <nav className={ scrollStyles } style={{height: '123px'}}>
      <div className="container-fluid g-0">
        <a href="/home" className="logo">
          <img src={logo} alt="no resource" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation">
          <img
            src={toggled === false? cross : line}
            key={toggled ? 1 : 0}
            alt=""
            className="my-hamburger-icon"
            onClick={() => canToggle && setToggled(!toggled)}
          />
        </button>
        <div
          className={`collapse ${width >= 1000 ? 'navbar-collapse d-flex justify-content-end' : ''} background   `}
          id="navbarSupportedContent">
          <ul className="navbar-nav mb-lg-0">
            <li className="nav-item my-border" data-bs-toggle="collapse">
              <Link to="/apartamentos" data-bs-toggle="collapse"
              onClick={() => canToggle && setToggled(!toggled)}>
                <div data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" className={`${pathName==='/apartamentos' ? 'my-border-bottom' : ''} my-link-container`}>
                  <p>APARTAMENTOS</p>
                </div>
              </Link>
            </li>
            <li className="nav-item my-border">
              <Link to="/workspaces"
               onClick={() => canToggle && setToggled(!toggled)}>
                <div data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" className={`${pathName.indexOf('/workspaces')===0 ? 'my-border-bottom' : ''} my-link-container`}>
                  <p>WORKSPACES</p>
                </div>
              </Link>
            </li>
            <li className="nav-item my-border">
              <Link to="/comercio"
              onClick={() => canToggle && setToggled(!toggled)}>
                <div data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" className={`${pathName==='/comercio' ? 'my-border-bottom' : ''} my-link-container`}>
                  <p>COMERCIO</p>
                </div>
              </Link>
            </li>
            <li className="nav-item my-border">
              <Link to="/gastronomia"
              onClick={() => canToggle && setToggled(!toggled)}>
                <div data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" className={`${pathName==='/gastronomia' ? 'my-border-bottom' : ''} my-link-container`}>
                  <p>GASTRONOMÍA</p>
                </div>
              </Link>
            </li>
            <li className="nav-item my-border">
              <Link to="/espacios-quo"
              onClick={() => canToggle && setToggled(!toggled)}>
                <div  data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" className={`${pathName.indexOf('/espacios-quo')===0   ? 'my-border-bottom' : ''} my-link-container`}>
                  <p>ESPACIOS QUO</p>
                </div>
              </Link>
            </li>
            
            <li className="nav-item my-border">
              <Link to="/cultura-quo"
              onClick={() => canToggle && setToggled(!toggled)}>
                <div  data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" className={`${pathName.indexOf('/cultura-quo')===0   ? 'my-border-bottom' : ''} my-link-container`}>
                  <p>CULTURA QUO</p>
                </div>
              </Link>
            </li>
            
            <li className="nav-item my-border">
              <Link to="/contacto"
              onClick={() => canToggle && setToggled(!toggled)}>
                <div data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" className={`${pathName === '/contacto' ? 'my-border-bottom' : ''} my-link-container contacto`} to="/contacto">
                  <p>CONTACTO</p>
                </div>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default NavBar;