import React, {useEffect, useState} from 'react';
import {
  Switch,
  withRouter,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom";
import Home from '../Home';
import Business from '../Business';
import Gastronomy from '../GastronomyGeneral';
import QuoSpaces from '../QuoSpaces';
import QuoSpacesDetail from '../QuoSpaces/Detail';
import Workspaces from '../Workspaces';
import Galery from '../Galery';
import Footer from '../Footer';
import Contact from '../Contact';
import './styles.scss';
import { ParallaxProvider } from 'react-scroll-parallax';

import Apartments from '../Apartments';
import QuoWorkspacesDetail from '../Workspaces/Detail';
import CulturaQuo from '../CulturaQuo';


const HOME = (props) => (
  <ParallaxProvider>
  <div className="background">
    <Galery {...props}/>
    <Footer />
  </div>
  </ParallaxProvider>
)

const APARTMENTS = () => (
  <div className="background">
    <Apartments/>
    <Footer />  
  </div>
)

const WORKSPACES = () => (
  <div className="background">
    {/* <Home /> */}
    <Workspaces />
    <Footer />
  </div>
)

const WORKSPACES_DETAIL = () => (
  <div className="background">
    {/* <Home /> */}
    <QuoWorkspacesDetail />
    <Footer />
  </div>
);

const BUSINESS = () => (
  <div className="background">
    {/* <Home /> */}
    <Business />
    <Footer />
  </div>
)

const GASTRONOMY = () => (
  <div className="background">
    {/* <Home /> */}
    <Gastronomy />
     <Footer />
  </div>
 
)

const QUOSPACES = () => (
  <div className="background">
    {/* <Home /> */}
    <QuoSpaces />
    <Footer />
  </div>
)

const QUOSPACES_DETAIL = () => (
  <div className="background">
    {/* <Home /> */}
    <QuoSpacesDetail />
    <Footer />
  </div>
);

const CONTACT = (props) => (
  <div className="background">
    {/* <Home /> */}
    <Contact {...props}/>
    <Footer />
  </div>
)

const CULTURA = (props) => (
  <div className="background">
    {/* <Home /> */}
    <CulturaQuo {...props}/>
    <Footer />
  </div>
)

const ContentHome = withRouter((props) => <HOME {...props} />);
const ContentApartments = withRouter(APARTMENTS);
const ContentWorkspaces = withRouter(WORKSPACES);
const ContentBusiness = withRouter(BUSINESS);
const ContentGastronomy = withRouter(GASTRONOMY);
const ContentQuoSpaces = withRouter(QUOSPACES);
const ContentQuoWorkspacesDetail = withRouter(WORKSPACES_DETAIL);
const ContentQuoSpacesDetail = withRouter(QUOSPACES_DETAIL);
const ContentCultura = withRouter(CULTURA)
const ContentContact = withRouter((props) => <CONTACT {...props} />);

const App = () => {
  const location = useLocation()
  useEffect(() => window.scrollTo(0, 0), [location]);
  const [selectedValue, setSelectedValue] = useState(-1);
    return (
      <>
      <Home />
       
        <Switch>
          <Route exact path="/" component={() => <ContentHome selectedValue={selectedValue} setSelectedValue={setSelectedValue} />} />
          <Route exact path="/apartamentos" component={() => <ContentApartments />} />
          <Route exact path="/workspaces" component={() => <ContentWorkspaces />} />
          <Route exact path="/workspaces/detail" component={() => <ContentQuoWorkspacesDetail />} />
          <Route exact path="/comercio" component={() => <ContentBusiness />} />
          <Route exact path="/gastronomia" component={() => <ContentGastronomy />} />
          <Route exact path="/espacios-quo/detail" component={() => <ContentQuoSpacesDetail />} />
          <Route exact path="/espacios-quo" component={() => <ContentQuoSpaces />} />
          <Route exact path="/cultura-quo" component={() => <ContentCultura selectedValue={selectedValue} setSelectedValue={setSelectedValue} />} />
          <Route exact path="/contacto" component={() => <ContentContact selectedValue={selectedValue} setSelectedValue={setSelectedValue} />} />
          <Redirect to="/" />
        </Switch>
      </>
    );
}

export default App;