import React, { useEffect, useState, useRef } from "react";
import {Link} from "react-router-dom";
import './styles.scss';
import Detail from './Detail'
import right_arrow from '../../assets/images/quospaces/right-arrow.svg';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

const scrollToRef = (ref) => {
  const { height } = getWindowDimensions();
  const { width } = getWindowDimensions();
  if (width > 1500) {
    window.scrollTo({ top: height * 0.9, behavior: 'smooth' })
  }
  else {
    window.scrollTo({ top: height * 0.85, behavior: 'smooth' })
  }
}

const QuoSpaces = () => {
  const myRef = useRef(null);
  return (
    <div className="container-fluid g-0 my-container-spaces" ref={myRef}>
      <div ClassName="row g-0">
        <div className="col my-header-spaces" >
          <div className="quo-spaces-random-box" />
          <h1>
            SPACES FOR ALL<br />KIND OF EVENTS
          </h1>
          {/* <Link to="/espacios-quo/detail"> */}
           {/*} <div
              className="ingresa-button"
              onClick={() => scrollToRef(myRef)}>
              INGRESA
              <img src={right_arrow} alt="right arrow" />
            </div>
          {/* </Link> */}
        </div>
        <Detail />
      </div>
    </div>
  )
}

export default QuoSpaces;