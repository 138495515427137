import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import ReactPixel from 'react-facebook-pixel';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
import './styles/custom.scss'

const history = createBrowserHistory();
const advancedMatching = { em: 'lpalomo@devpackgroup.com', };
const options = {
  autoConfig: true,
  debug: false,
};
ReactPixel.init('740990403282883', advancedMatching, options);
ReactPixel.pageView();

ReactDOM.render(
  <React.StrictMode>
    <Router history={history}>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
