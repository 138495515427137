import React, { useState, useEffect } from 'react';
import './styles.scss';
import logo from '../../assets/images/logo.svg'
import rosul from '../../assets/LOGOROSUL.svg'
import facebook from '../../assets/images/Footer/Facebook.svg'
import waze from '../../assets/images/Footer/Waze.svg'
import instagram from '../../assets/images/Footer/ig.svg'
import linkedin from '../../assets/images/Footer/LinkedIn.svg'
import whatsapp from '../../assets/images/Contacto/whatsapp.svg'
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

function Footer() {
  const { width } = useWindowDimensions();
  return width >= 768 ? (
    <div className="row gx-0 align-items-center justify-content-md-center myContainerfooter" style={{zIndex: `${window.location.pathname === '/cultura-quo' && 0}`}}>
      <div className="col-3 left-text">
        <div className="container-text-footer">
          <p className="">CONTÁCTANOS</p>
          <p>QUO / VÍA 7 04-20 ZONA 4</p>
          <p><img src={whatsapp} alt="" className='phone-contact' /> 5966-1111</p>
        </div>
      </div>
      <div className="col-2">
        <div className="container-text-footer">
        <p className="margisnBlue"><a href="/cultura-quo">¿QUÉ ESTÁ PASANDO EN QUO?</a></p>
        <p className=""><a href="https://waze.com/ul/h9fxehm0yp">¿CÓMO LLEGAR AL PARQUEO?</a></p>
        <p><a href="/gastronomia">¿QUÉ VAS A COMER HOY?</a></p>
        </div>
      </div>
      <div className="col-2 my-footer-row text-center">
      <div className="container-text-footer">
        <p className="margin-search">BÚSCANOS EN:</p>
        <div className="row icons-row justify-content-between fil-iconos">
          <div className="col-3 g-0 icon-individual" align="center">
            <a href="https://m.facebook.com/QUOgt/">
              <img src={facebook} className="d-inline " alt="no resource" />
            </a>
          </div>
          <div className="col-3 g-0 icon-individual" align="center">
            <a href="https://waze.com/ul/h9fxehm0yp">
              <img src={waze} className="d-inline waze" alt="no resource" />
            </a>
          </div>
          <div className="col-3 g-0 icon-individual" align="center">
            <a href="https://instagram.com/quo_gt?utm_medium=copy_link">
              <img src={instagram} className="d-inline  " alt="no resource" />
            </a>
          </div>
          <div className="col-3 g-0 icon-individual" align="center">
            <a href="https://www.linkedin.com/company/quo-oficinas">
              <img src={linkedin} className="d-inline " alt="no resource" />
            </a>
          </div>
        </div>
        </div>
      </div>
      <div className="col-5">
      <div className="container-text-footer">
        <div className="row last-col-wrapper">
          <div className="col align-items-center g-0 rosul-project" align="center">
            <p className="marginProyecto">UN PROYECTO DE:</p>
            <img src={rosul} alt="" className="img-fluid rosul-logo" />
          </div>
          <div className="separator" />
          <div className="col align-items-center g-0 quo-project" align="center">
            <img src={logo} alt="" className="img-fluid quo-logo" />
            <p className="">Todos los derechos reservados	&copy;</p>
          </div>
        </div>
        </div>
      </div>
      {/* <div className="col-1"/> */}
      </div>
  ) : (
    <div className="row g-0 align-items-center justify-content-md-center responsive-footer" style={{zIndex: `${window.location.pathname === '/cultura-quo' && 0}`}}>
      <div className="col-7 rosul-responsive-col">
        <img src={rosul} alt="" className="img-fluid responsive-rosul" />
      </div>
      <div className="col-4 responsive-icons-col">
        <div className="row justify-content-center mb-2">
          <div className="col-3 g-0">
            <a href="https://m.facebook.com/QUOgt/">
              <img src={facebook} className="d-inline " alt="no resource" />
            </a>
          </div>
          <div className="col-3  g-0">
            <a href="https://waze.com/ul/h9fxehm0yp">
              <img src={waze} className="d-inline " alt="no resource" />
            </a>
          </div>
          <div className="col-3  g-0">
            <a href="https://instagram.com/quo_gt?utm_medium=copy_link">
              <img src={instagram} className="d-inline  " alt="no resource" />
            </a>
          </div>
          <div className="col-3  g-0">
            <a href="https://www.linkedin.com/company/quo-oficinas">
              <img src={linkedin} className="d-inline " alt="no resource" />
            </a>
          </div>
        </div>
        <p className="">TODOS LOS DERECHOS RESERVADOS</p>
      </div>
    </div>
  );
}

export default Footer;