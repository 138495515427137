import React, {useState, useEffect} from "react";
import './styles.scss';
import Form from '../General/Form';
import circulo from '../../assets/images/Form/upper.svg'
import waze from '../../assets/images/Footer/Waze.svg'
import waze_amarillo from '../../assets/images/Contacto/waze_amarillo.svg'
import location from '../../assets/images/Contacto/location.svg'
import location_blanco from '../../assets/images/Contacto/location_blanco.svg'
import whatsapp from '../../assets/images/Contacto/whatsapp.svg'
import c1 from '../../assets/images/Form/below.png'

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

const TextRow = ({ text, whats, park, clas, }) => {
    return (
      <div className="row g-0 text-center my-text-row ">
        <p className={clas}>
          {
            whats &&
            <img src={whatsapp} alt="" className="whatsapp" />
          }
          {text}
          {park && (
            <>
              <img src={waze} 
              onMouseOver={e => (e.currentTarget.src = waze_amarillo)}
              onMouseOut={e => (e.currentTarget.src = waze)}
              alt="" className="" />
              <img
                src={location_blanco}
                onMouseOver={e => (e.currentTarget.src = location)}
                onMouseOut={e => (e.currentTarget.src = location_blanco)}
                alt=""
                className="" />
            </>
          )}
        </p>
      </div>
    )}

const Contact = (props) => {
  const {
    selectedValue,
    setSelectedValue,
  } = props;
  const { width } = useWindowDimensions();

  return (
    <div className="col g-0 my-container-contact">
      <div className="row g-0">
        <div className="col-lg-6">
          <Form isContact selectedValue={selectedValue} setSelectedValue={setSelectedValue} />
        </div>
        <div className="form-separator" />

        <div className="col-lg-5 justify-content-between">
          <div className="row offset-10 ">
            <div className="col d-none d-md-block">
              <img src={circulo} className="circle g-0" alt="" />
            </div>
          </div>
          <div className="d-block d-md-none  random-box-contact" />
          <div className="d-container-text">
            {<TextRow text="QUO / VÍA 7 04-20 ZONA 4" clas="text-p"/>}
            {<TextRow text="5966-1111" whats clas="text-whatsapp"/>}
            {<TextRow text="ACCESO A PARQUEO" park  clas="text-parking"/>}
          </div>
          <div className="d-none d-md-block  random-box-contact" />
          <div className="col-3 offset-1 g-0 d-block d-md-none align-self-end">
            <img alt="" className="end-circle1" src={c1} />
          </div>
        </div>
      </div>
      <div className="row row-map">
        <iframe
          title="Mapa"
          src={
            width > 450 ?
            "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d482.5826066709181!2d-90.51527178853227!3d14.618385780561127!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8589a231f18f9e85%3A0x839dbdb65ad57726!2sQuo!5e0!3m2!1ses!2sgt!4v1627837559979!5m2!1ses!2sgt" :
            "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1930.3298536754055!2d-90.5157810455276!3d14.618450989138548!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8589a231f18f9e85%3A0x839dbdb65ad57726!2sQuo!5e0!3m2!1ses-419!2sgt!4v1631074861205!5m2!1ses-419!2sgt"
          }
          width="100%"
          height="100%"
          style={{
            'border': 0,
          }}
          className="map"
          allowfullscreen=""
          loading="lazy" />
      </div>
    </div>
  )
}

export default Contact;