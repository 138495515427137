import React, { useState, useEffect, useRef } from "react";
import Modal from 'react-modal';
import './styles.scss';
import Slider from "react-slick";
import '@brainhubeu/react-carousel/lib/style.css';
import n1gris from '../../assets/images/comercio/mapas/Mapas1/SFgris.svg'
import plus from '../../assets/images/apartamentos/general/plus.svg'
import negocios from './list';

import level_one from '../../assets/icons/comercio/level1.svg';

import facebook from '../../assets/icons/facebook-rounded.svg'
import instagram from '../../assets/icons/instagram-rounded.svg'
import website from '../../assets/icons/website-rounded.svg'
import catalogo from '../../assets/icons/catalogo-rounded.svg'

// import ICONS
import elevators from '../../assets/icons/comercio/elevators.svg';
import parking from '../../assets/icons/comercio/parking.svg';
import restrooms from '../../assets/icons/comercio/restrooms.svg';
import stairs from '../../assets/icons/comercio/stairs.svg';
import tree from '../../assets/icons/comercio/tree.svg';

const modalClassNames = {
  base: 'business-modal-class',
  afterOpen: 'business-modal-class--after-open',
  beforeClose: 'business-modal-class--before-close',
  overlay: 'business-modal-class-overlay',
}


const scrollToRef = (ref) => {
  // eslint-disable-next-line no-restricted-globals
  if (screen.width < 500) {
    // eslint-disable-next-line no-restricted-globals
    window.scroll({top: screen.height*0.64, behavior: 'smooth'})
  }
  // eslint-disable-next-line no-restricted-globals
  if (screen.width > 500) {
    // eslint-disable-next-line no-restricted-globals
    window.scroll({top: screen.height*0.3, behavior: 'smooth'})
  }
}

const Business = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedBussines, setSelectedBussines] = useState({});
  const myRef = useRef(null);
  const NegocioCard = (props) => {
    const {
      name,
      type,
      location,
      logo,
      onClick,
    } = props;
    return (
      <div class={`card card-fix mb-3 p-2 my-busines-card ${selectedBussines.name ? '' : 'hide-card'}`}>
        <div class="row g-0 h-100">
          <div class="col-5 h-100 my-card-img p-0 p-md-2 d-flex flex-column justify-content-center">
            <img src={logo} class="img-fluid img-logo" alt="..." style={{filter: `${selectedBussines.name === 'GRUPO ROSUL' && 'invert(1)'}`}} />
          </div>
          <div class="col-7 d-flex flex-column justify-content-center">
            <div class="card-body card-body-fix card-text card-text-fix d-flex flex-column justify-content-center">
              <h1 class="card-title">{name}</h1>
              <p class="card-text card-text-fix p-0 m-0">{type}</p>
              <p class="card-text card-text-fix p-0 m-0">
                <small class="text-muted">
                  {location}
                </small>
              </p>
            </div>
            <div className="d-flex button-card justify-content-end">
              <button onClick={() => onClick()}>
                <p>
                  VER MÁS <img src={plus} alt='' className='plus' />
                </p>

              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const NegocioButton = (props) => {
    const {
      name,
      white,
      reference
    } = props;
    return (

      <div className={white ?
        'col-6 col-md-2 col-sm-12 d-flex align-items-center justify-content-md-center justify-content-start py-1 px-1 px-md-0 mx-md-3 mb-1 vw-20 ' :
        'col-6 col-md-2 col-sm-12 d-flex align-items-center justify-content-md-center justify-content-start py-1 px-1 px-md-0 mx-md-3 mb-1 vw-20 button-container mr-2' }
        onClick={() => setSelectedBussines(props)}
      >
        <div className={`my-button ${(selectedBussines.name === name && !white) ? 'selected-button' : ''}`} onClick={() => scrollToRef(reference)}>
          <p>{white ? '' : name}</p>
        </div>
      </div>
    )
  }

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }
  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
  }

  const NegocioModal = (props) => {
    const {
      modalIsOpen,
      afterOpenModal,
      closeModal,
    } = props;
    const { width } = useWindowDimensions();
    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      speed: 1000,
      fade: true,
      autoplaySpeed: 3000,
      adaptiveHeight: true,
      cssEase: "linear"
    };
    return (
      <Modal
        isOpen={modalIsOpen}
        closeTimeoutMS={500}
        className={modalClassNames}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
      >
        <div className='row g-0 my-modal-container'>

          <div className='col-6 my-modal-imageX d-block d-md-none'>
            <p onClick={closeModal}>&#x2715;</p>
          </div>
          <div className='col-12 col=sm-4 col-md-6 d-flex flex-column justify-content-center div-modal'>
            <div className="icons d-none d-md-block">
              {selectedBussines.facebook && <div className="row icon-top  me-2 px-2 mt-1  ">
                <img
                  onClick={() => window.location.assign(selectedBussines.facebook)}
                  src={facebook}
                  alt=""
                  className='social-icons' />
              </div>}
              {selectedBussines.instagram && <div className={`row ${selectedBussines.facebook ? 'icon-bottom' : 'icon-top'} me-2 px-2 mt-1`}>
                <img
                  onClick={() => window.location.assign(selectedBussines.instagram)}
                  src={instagram}
                  alt=""
                  className='social-icons' />
              </div>}
              {selectedBussines.otherLink ? <div className={`row ${(selectedBussines.facebook || selectedBussines.instagram) ? 'icon-bottom' : 'icon-top'} me-2 px-2 mt-1`}>
                <img
                  onClick={() => window.location.assign(selectedBussines.otherLink)}
                  src={website}
                  alt=""
                  className='social-icons' />
              </div>: <></>}
              {selectedBussines.catalogo && <div className="row icon-bottom2  me-2 px-2 mt-1  ">
                <img
                  onClick={() => window.location.assign(selectedBussines.catalogo)}
                  src={catalogo}
                  alt=""
                  className='social-icons' />
              </div>}
            </div>
            <div className='row modal-img g-0'>
              <div className='col-10 d-none d-lg-block image-modal d-flex flex-column align-items-center pt-3 pt-lg-4'>
                <img src={selectedBussines.logo} alt='' className={`${selectedBussines.name === 'ROSA ALACRÁN' || selectedBussines.name === 'LA TORRE' || selectedBussines.name === 'SMART FIT' || selectedBussines.name === 'DUDES PIZZERÍA' || selectedBussines.name === 'GLAD' || selectedBussines.name === 'FRSH' ? 'modal-logoT' : 'modal-logo' } ps-sm-3`} style={{filter: `${selectedBussines.name === 'GRUPO ROSUL' && 'invert(1)'}`}} />
              </div>
              <div className='col-10 d-block d-lg-none image-modal d-flex flex-column align-items-center pt-3 pt-lg-4'>
                <img src={selectedBussines.logo} alt='' className={`modal-logo ps-sm-3`}  style={{filter: `${selectedBussines.name === 'GRUPO ROSUL' && 'invert(1)'}`}} />
              </div>
            </div>
            <div className="row g-0  text-modal ">
              <h1 class="mt-3 mb-0">{selectedBussines.name}</h1>
              <p class="card-text card-text-fix p-0 comercio-type">{selectedBussines.type}</p>
              {
                selectedBussines.days && selectedBussines.days.map((value, index) => {
                  return width >=768 ?(
                    <div className="comercio-horario ms-4 ps-1">
                      <p className={`comercio-horario-dia ${(selectedBussines.name === "BUNS BUNNYS" || selectedBussines.name === "VINARIUMS" || selectedBussines.name === "CRUZ VERDES")&& 'comercio-proximamente  text-center'}`}>{value}</p>
                      <p>{selectedBussines.time[index]}</p>
                    </div>
                  ) : (
                    <div className="comercio-horario">
                      <p className={`comercio-horario-dia ${(selectedBussines.name === "BUNS BUNNYS" || selectedBussines.name === "VINARIUMS" || selectedBussines.name === "CRUZ VERDES")&& 'comercio-proximamente  '}`}>{value}</p>
                      <p className="comercio-horario-horas">{selectedBussines.time[index]}</p>
                    </div >
                  )
                })
              }
            </div>
            <div className='row g-0'>
              <div className={`col-1 ${selectedBussines.catalogo? `${selectedBussines.otherLink? `${selectedBussines.instagram? 'offset-9': 'offset-10'   }`: 'offset-10'}`: 'offset-10'}  d-block d-md-none`}>
                <div>
                  <img
                    onClick={() => window.location.assign(selectedBussines.facebook)}
                    src={facebook}
                    alt=""
                    className='social-icons'
                    style={selectedBussines.facebook==='' ? {display: 'none'} : {} }
                    />
                </div>
              </div>
              {selectedBussines.instagram!==''&&
    <div className='col-1 d-block d-md-none'>
    <div>
      <img
        onClick={() => window.location.assign(selectedBussines.instagram)}
        src={instagram}
        alt=""
        className='social-icons'
        style={selectedBussines.instagram==='' ? {display: 'none'} : {} }
        />
    </div>
  </div>

              }
          
              <div className='col-1 d-block d-md-none'>
                <div>
                  <img
                    onClick={() => window.location.assign(selectedBussines.otherLink)}
                    src={website}
                    alt=""
                    className='social-icons'
                    style={selectedBussines.otherLink==='' ? {display: 'none'} : {} }
                    />
                </div>
              </div>
              <div className='col-1 d-block d-md-none'>
                <div>
                  <img
                    onClick={() => window.location.assign(selectedBussines.catalogo)}
                    src={catalogo}
                    alt=""
                    className='social-icons'
                    style={selectedBussines.catalogo==='' ? {display: 'none'} : {right: '86px'} }
                    />
                </div>
              </div>
            </div>
          </div>
          <div className='col-6 my-modal-image h-100 d-block d-md-none' >
            <Slider {...settings}>
          {  selectedBussines.images && selectedBussines.images.length !== 0 && selectedBussines.images.map((value, index) => (
        
          <img src={value} className='d-block h-100' alt='...' />
        
      ))}
        </Slider>
          </div>
          <div className='col-6 my-modal-image d-none d-md-block'>
            <p onClick={() => closeModal()}>&#x2715;</p>
            <Slider {...settings}>
          {  selectedBussines.images && selectedBussines.images.length !== 0 && selectedBussines.images.map((value, index) => (
        (
          <img src={value} className='d-block h-100' alt='...' />
        )
      ))}
        </Slider>
          </div>
        </div>
      </Modal>
    )
  }

  return (
    <div className="
      container-fluid
      g-0
      align-items-center
      my-container-business
      ">
      <NegocioModal modalIsOpen={modalOpen} closeModal={() => setModalOpen(false)} />
      <div className="row g-0 mb-5 d-flex  row-comercios">
        {
          negocios.map(value => (
            <NegocioButton {...value} reference={myRef} />
         
          ))
        }
      </div>
      <div className='row g-0 d-flex justify-content-between row-maps'>
        <div className='col-2 offset-7 offset-lg-0 col-lg-2 order-2 card-maps order-md-1 d-flex flex-column justify-content-between col-info'>
          <div className='row-4 map-key'>
            <div className='col align-items-center ms-1'>
              <div className='row my-icons-row d-flex justify-content-center'>
                <div className='col-5'>
                  <img src={parking} alt='' className='img-fluid  ps-2' />
                </div>
                <div className='col g-0 align-items-center my-auto'>
                  PARQUEO
                </div>
              </div>
              <div className='row my-icons-row'>
                <div className='col-5'>
                  <img src={stairs} alt='' className='img-fluid  ps-2' />
                </div>
                <div className='col g-0 align-items-center my-auto'>
                  ESCALERAS ELÉCTRICAS
                </div>
              </div>
              <div className='row my-icons-row'>
                <div className='col-5'>
                  <img src={restrooms} alt='' className='img-fluid  ps-2' />
                </div>
                <div className='col g-0 align-items-center my-auto'>
                  BAÑOS
                </div>
              </div>
              <div className='row my-icons-row'>
                <div className='col-5'>
                  <img src={elevators} alt='' className='img-fluid  ps-2' />
                </div>
                <div className='col g-0 align-items-center my-auto'>
                  ELEVADORES
                </div>
              </div>
              <div className='row my-icons-row'>
                <div className='col-5'>
                  <img src={tree} alt='' className='img-fluid  ps-2' />
                </div>
                <div className='col g-0 align-items-center my-auto'>
                  ÁRBOL DE<br />LA PLAZA
                </div>
              </div>
            </div>
          </div>
          <div className='row align-items-center image-level low-text'>
            <img alt='' src={selectedBussines.level ? selectedBussines.level : level_one} className='img-fluid' />
          </div>
        </div>
        <div className='col-8 order-3 order-md-2 col-map'>
          <img alt='' src={selectedBussines.map ? selectedBussines.map : n1gris} className='img-fluid image-map' />
        </div>
        <div className='
          col-4
          col-md-2
          offset-7
          offset-sm-5
          offset-md-0
          order-1 
          order-md-3
          col-more-info' ref={myRef}>
          <NegocioCard {...selectedBussines} onClick={() => setModalOpen(true)} />
        </div>
      </div>
    </div>
  )
}

export default Business;