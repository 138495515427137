import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import circle0 from "../../../assets/images/quospaces/circle.svg";
import "./styles.scss";
import whatsapp from "../../../assets/images/quospaces/whatsapp.svg";
import p01 from "../../../assets/images/quospaces/carousel/01.jpg";
import p02 from "../../../assets/images/quospaces/carousel/02.jpg";
import p03 from "../../../assets/images/quospaces/carousel/03.jpg";
import p04 from "../../../assets/images/quospaces/carousel/04.jpg";
import p05 from "../../../assets/images/quospaces/carousel/05.jpg";
import p06 from "../../../assets/images/quospaces/carousel/06.jpg";
import p07 from "../../../assets/images/quospaces/carousel/07.jpg";
import p08 from "../../../assets/images/quospaces/carousel/08.jpg";
import p09 from "../../../assets/images/quospaces/carousel/09.jpg";
import p10 from "../../../assets/images/quospaces/carousel/10.jpg";
import p11 from "../../../assets/images/quospaces/carousel/11.jpg";
import p12 from "../../../assets/images/quospaces/carousel/12.jpg";
import p13 from "../../../assets/images/quospaces/carousel/13.jpg";
import p14 from "../../../assets/images/quospaces/carousel/14.jpg";
import p15 from "../../../assets/images/quospaces/carousel/15.jpg";
import p16 from "../../../assets/images/quospaces/carousel/16.jpg";
import Slider from "react-slick";
import pdf1 from "../../../assets/pdf/pdf1.jpg";
import pdf2 from "../../../assets/pdf/pdf2.jpg";
import pdf3 from "../../../assets/pdf/pdf3.jpg";
import pdf4 from "../../../assets/pdf/pdf4.jpg";
import pdf5 from "../../../assets/pdf/pdf5.jpg";
import pdf6 from "../../../assets/pdf/pdf6.jpg";
import pdf7 from "../../../assets/pdf/pdf7.jpg";
import pdf8 from "../../../assets/pdf/pdf8.jpg";
import pdf9 from "../../../assets/pdf/pdf9.jpg";
import pdf10 from "../../../assets/pdf/pdf10.jpg";
import pdf11 from "../../../assets/pdf/pdf11.jpg";
import pdf12 from "../../../assets/pdf/pdf12.jpg";
import pdf13 from "../../../assets/pdf/pdf13.jpg";
import pdf14 from "../../../assets/pdf/pdf14.jpg";
import pdf15 from "../../../assets/pdf/pdf15.jpg";
import arrowpdf from "../../../assets/pdf/arrowpdf.svg";

import Iframe from "react-iframe";
//import { Document, Page } from 'react-pdf';
import pdfFondo from "../../../assets/images/quospaces/pdf.png";
import documentPDF from "../../../assets/pdf/pdfQUO.pdf";
import axios from 'axios';

const options = {
  cMapUrl: "cmaps/",
  cMapPacked: true,
};

const data = {
    name: "RSA",
    images: [
        p03,
        p04,
        p05,
        p06,
        p07,
        p08,
        p12,
        p13,
        p15,
        p16,
    ]
}
const dataPDF = {
  images: [
    pdf1,
    pdf2,
    pdf3,
    pdf4,
    pdf5,
    pdf6,
    pdf7,
    pdf8,
    pdf9,
    pdf10,
    pdf11,
    pdf12,
    pdf13,
    pdf14,
    pdf15,
  ],
};

const QuoSpacesDetail = () => {
  const [isSending, setIsSending] = useState(false);
  const [validated, setValidated] = useState(false);

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [espacioInteres, setEspacioInteres] = useState();
  const [tipoEvento, setTipoEvento] = useState();
  const [comentarios, setComentarios] = useState("");
  const setNameValidate = (value) => {
    if (!/[0-9]$/.test(value)) {
      setName(value);
    }
  };

  const nameRegex = RegExp(/^[A-Za-z]{3,}/);

  const emailRegex = RegExp(
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
  );

  const phoneRegex = RegExp(/^[0-9]{8}/);

  const fakeSend = () => {
    if (
      nameRegex.test(name) &&
      emailRegex.test(email) &&
      phoneRegex.test(phone) &&
      espacioInteres &&
      tipoEvento
    ) {
      quoSpacesForm(name,email,phone, espacioInteres, tipoEvento, comentarios)
      setIsSending(true);
      setTimeout(() => {
        setIsSending(false);
        setValidated(true);
        setTimeout(() => setValidated(false), 2000);
      }, 3000);
    } else {
      alert("Por favor ingrese todos los campos");
    }
  };

  const quoSpacesForm = (name, mail, phone, interes, evento, comment) => {
    const formData = new FormData();
    name && formData.append("name", name );
    mail && formData.append("email", mail);
    phone && formData.append("phone", phone);
    interes && formData.append("options", interes);
    evento && formData.append("event", evento);
    comment && formData.append("comments", comment);
    formData.append("form", "2");
    axios.post(
      'https://api-ecommerce.devpackgroup.com/api/v1/send_email_quo/',
      formData,
      {
        headers: {
          // "Authorization": "Token " + this.userToken,
        }
      }
    ).then(function (response) {

    }.bind(this)).catch(function (error) {

    }.bind(this));
  }

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 3000,
    arrows: false,
  };

  const [numPages, setNumPages] = useState(null);
  let [pageNumber, setPageNumber] = useState(0);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function goToPrevPage({ pageNumber }) {
    setPageNumber(pageNumber - 1);
  }
  function goToNextPage({ pageNumber }) {
    console.log(pageNumber);
  }

  return (
    <div className="container-fluid my-container-spaces-detail">
      <div className="row quo-spaces-row-1 g-0">
        <div className="col-12 col-lg-5 justify-content-center d-flex flex-column info-col">
          <img className="random-circle-0" src={circle0} alt="" />
          <div className="random-box-0" />
          <p className="info-text">
            <strong>ESPACIOS QUO</strong><br></br> Es el destino perfecto para tus
            eventos por nuestra ubicación céntrica, con acceso y conectividad a
            las zonas principales de la ciudad, seguridad y nuestro amplio
            parqueo.
          </p>

          <p className="email">espacios@quo.com.gt</p>
          <div className="phone">
            <img src={whatsapp} alt="" />
            <p>(+502) 5966-1111</p>
          </div>
        </div>
        <div className="col-12 col-lg-7 back-form">
          <div className="form-quo-spaces">
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
            />
            <div className="row">
              <div className="col  ">
                <input
                  type="text"
                  className="input-name"
                  placeholder="NOMBRE"
                  value={name}
                  onChange={(e) => setNameValidate(e.target.value)}
                  style={
                    name && !nameRegex.test(name)
                      ? { border: "2px solid #CD405C" }
                      : { border: "2px solid #11bbbb" }
                  }
                />
              </div>
              <div className="col">
                <input
                  type="number"
                  className="input-name"
                  placeholder="TELÉFONO"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  style={
                    phone && !phoneRegex.test(phone)
                      ? { border: "2px solid #CD405C" }
                      : { border: "2px solid #11bbbb" }
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="col ">
                <input
                  type="text"
                  className="input-email"
                  placeholder="CORREO ELECTRÓNICO"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  style={
                    email && !emailRegex.test(email)
                      ? { border: "2px solid #CD405C" }
                      : { border: "2px solid #11bbbb" }
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="col select-venta">
                <select
                  className="select"
                  value={espacioInteres}
                  onChange={(e) => setEspacioInteres(e.target.value)}
                  placeholder="ESPACIO DE INTERES"
                >
                  <option selected disabled hidden value="">
                    ESPACIO DE INTERÉS
                  </option>
                  <option value="Salones Privados o Terraza para Eventos">
                    Salones Privados o Terraza para Eventos
                  </option>
                  <option value="Plaza QUO">Plaza QUO</option>
                  <option value="Otro">Otro</option>
                </select>
              </div>
              <div className="col select-mueble">
                <select
                  value={tipoEvento}
                  onChange={(e) => setTipoEvento(e.target.value)}
                  className="select"
                >
                  <option selected disabled hidden value="">
                    TIPO DE EVENTO
                  </option>
                  <option value="Corporativo">Corporativo</option>
                  <option value="Social">Social</option>
                  <option value="Otro">Otro</option>
                </select>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <textarea
                  rows="3"
                  cols="50"
                  placeholder="COMENTARIOS"
                  className=" textarea-form"
                  value={comentarios}
                  onChange={(e) => setComentarios(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="col ">
                <button
                  id="button"
                  type="button"
                  onClick={() => fakeSend()}
                  className={`${isSending ? "onclic-apts" : "something-apts"} ${
                    validated ? "validate-apts" : "something-apts"
                  } `}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row g-0">
          <div className="col-22 d-lg-block col-lg-6 offset-lg-3">
            <div className="carouselWork">
              <Slider {...settings}>
                {data.images &&
                  data.images.length !== 0 &&
                  data.images.map((value, index) => (
                    <div
                      style={{
                        width: "500px",
                        height: "30vh",
                        //  display: this.state.display ? "block" : "none"
                      }}
                    >
                      <img src={value} className="image-apt " alt="..." />
                    </div>
                  ))}
              </Slider>
            </div>
          </div>
        </div>
        <div className="row g-0">
          <div className="div-pfd">
            <img className="random-circle-pdf" src={circle0} alt="" />
            <img className="random-circle-pdf-left" src={circle0} alt="" />

            {/* </div> */}
            <div className="col-8 offset-2 d-none d-lg-block col-lg-8 offset-lg-0 pdfView">
              <iframe src="https://cdn.flipsnack.com/widget/v2/widget.html?hash=dn5wm59zhm" width="150%" height="680" seamless="seamless" scrolling="no" frameBorder="0" allowFullScreen></iframe>
            </div>

            <div className="div-logoblock"></div>

            <div className="col-12 offset-0 d-block d-lg-none col-lg-8 offset-lg-2 image-pdf-responsive">
            <iframe src="https://cdn.flipsnack.com/widget/v2/widget.html?hash=dn5wm59zhm"  width="100%" height="380" seamless="seamless" scrolling="no" frameBorder="0" allowFullScreen></iframe>
            </div>
            <div className="col-4 offset-4 d-lg-none download-button">
              <a href="https://quo-admin.s3.amazonaws.com/pdf/pdfQUO.pdf" target="_blank" download download="newfilename" style={{textDecoration: 'none', color: '#868686'}}>
              DESCARGAR PDF
              </a>
            </div>
            <div className="random-pdf" />
            <img
              className="random-circle-pdf-left-bottom"
              src={circle0}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuoSpacesDetail;
