import React, { useEffect, useState, useRef } from "react";
import './styles.scss';
import { Link } from "react-router-dom";
import right_arrow from '../../assets/images/quospaces/right-arrow.svg';
import Detail from './Detail';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

const scrollToRef = (ref) => {
  const { height } = getWindowDimensions();
  const { width } = getWindowDimensions();
  if (width > 1500) {
    window.scrollTo({ top: height * 0.9, behavior: 'smooth' })
  }
  else {
    window.scrollTo({ top: height * 0.85, behavior: 'smooth' })
  }
}


const Workspaces = () => {
  const myRef = useRef();
  return (
    <div className="container-fluid g-0 my-container-workspaces" ref={myRef}>
      <div className="row g-0">
        <div className="col my-header-workspaces g-0" >
          <div className="quo-workspaces-random-box"/>
          <h1>
            WORK<br />AND ENJOY
          </h1>
          {/* <Link to="/workspaces/detail"> */}
           {/*} <div
            className="ingresa-button-workspaces"
            onClick={() => scrollToRef(myRef)}>
              INGRESA
              <img src={right_arrow} alt="right arrow" />
            </div>/*}
          {/* </Link> */}
        </div>
       <Detail />
      </div>
    </div> )
}

export default Workspaces;