import React, { useState, useEffect } from "react";
import Modal from 'react-modal';
import './styles.scss';
import axios from 'axios';
//penthpuse
import apt0 from '../../../assets/images/apartamentos/penthouse/04.jpg'
import apt1 from '../../../assets/images/apartamentos/penthouse/02.jpg'
import apt2 from '../../../assets/images/apartamentos/penthouse/03.jpg'
import apt3 from '../../../assets/images/apartamentos/penthouse/01.jpg'
import apt4 from '../../../assets/images/apartamentos/penthouse/05.jpg'
import apt5 from '../../../assets/images/apartamentos/penthouse/06.jpg'
import apt6 from '../../../assets/images/apartamentos/penthouse/07.jpg'
import apt7 from '../../../assets/images/apartamentos/penthouse/08.jpg'

//apartamento O211
import aptN0 from '../../../assets/images/apartamentos/APARTAMENTOO211/01.jpg'
import aptN1 from '../../../assets/images/apartamentos/APARTAMENTOO211/02.jpg'
import aptN2 from '../../../assets/images/apartamentos/APARTAMENTOO211/03.jpg'
import aptN3 from '../../../assets/images/apartamentos/APARTAMENTOO211/04.jpg'
import aptN4 from '../../../assets/images/apartamentos/APARTAMENTOO211/05.jpg'

//apartamento O915
import apt915 from '../../../assets/images/apartamentos/APARTAMENTOO915/01.jpg'

//apartamento O1401
import apt1401 from '../../../assets/images/apartamentos/APARTAMENTOO1401/01.jpg'
import apt1402 from '../../../assets/images/apartamentos/APARTAMENTOO1401/02.jpg'
import apt1403 from '../../../assets/images/apartamentos/APARTAMENTOO1401/03.jpg'
import apt1404 from '../../../assets/images/apartamentos/APARTAMENTOO1401/04.jpg'
import apt1405 from '../../../assets/images/apartamentos/APARTAMENTOO1401/05.jpg'

//apartamento Q1009
import apta from '../../../assets/images/apartamentos/APARTAMENTOQ1009/01.jpg'
import apta1 from '../../../assets/images/apartamentos/APARTAMENTOQ1009/02.jpg'
import apta2 from '../../../assets/images/apartamentos/APARTAMENTOQ1009/03.jpg'
import apta3 from '../../../assets/images/apartamentos/APARTAMENTOQ1009/04.jpg'
import apta4 from '../../../assets/images/apartamentos/APARTAMENTOQ1009/05.jpg'
import apta5 from '../../../assets/images/apartamentos/APARTAMENTOQ1009/06.jpg'
import apta6 from '../../../assets/images/apartamentos/APARTAMENTOQ1009/07.jpg'
import apta7 from '../../../assets/images/apartamentos/APARTAMENTOQ1009/08.jpg'
import apta8 from '../../../assets/images/apartamentos/APARTAMENTOQ1009/09.jpg'



import plus from '../../../assets/images/apartamentos/general/plus.svg'
import mts from '../../../assets/images/apartamentos/general/mts.svg'
import roomsA from '../../../assets/images/apartamentos/general/rooms.svg'
import bathroomsimg from '../../../assets/images/apartamentos/general/bathrooms.svg'

import Detail from "../Detail";
import Footer from "../../Footer"
import circle0 from '../../../assets/images/apartamentos/general/circle0.svg'

const indicatorSeparatorStyle = {
  alignSelf: 'stretch',
  marginBottom: 8,
  marginTop: 8,
  width: 1,
};

const IndicatorSeparator = ({ innerProps }) => {
  return <span style={indicatorSeparatorStyle} {...innerProps} />;
};

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

const modalStylesMobile = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    padding: 0,
    height: '100%',
    width: '100%',
    borderRadius: 0,
    overflowY: 'auto',
    overflowX: 'clip',
    zIndex: '5',
    boxShadow: '5px 5px 3px rgba(157, 157, 157, 0.7)',
  },
  overlay: {
    zIndex: 4,
    backdropFilter: 'blur(20px)',
    WebkitBackdropFilter: 'blur(20px)'
  }

}

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    padding: 0,
    height: '105%',
    width: '100%',
    border: 0,
    borderRadius: 0,
    overflowY: 'auto',
    overflowX: 'clip',
    //transform: 'translate(-50%, -50%)',
    // transition: 'translate(50%, -50%) 0.3s ease-out',
    zIndex: '5',
    boxShadow: '5px 5px 3px rgba(157, 157, 157, 0.7)',
    //  background: 'gray'
  },
  overlay: {
    zIndex: 4,
    backdropFilter: 'blur(15px)',
    WebkitBackdropFilter: 'blur(15px)',
    //  background: 'gray'
  }
};

const selectOptions = [
  {
    value: '1',
    label: '1'
  },
  {
    value: '2',
    label: '2'
  },
  {
    value: '3',
    label: '3'
  },
]

const selectOptionsAmueblado = [
  { value: '1', label: 'SI' },
  { value: '2', label: 'NO' },
]


const ApartmentsGeneral = (props) => {

  useEffect(() => {
    getApartments()
  }, []);

  const [apartamentos, setApartamentos] = useState([])

  const getApartments = () => {
    axios.get(
      'https://testing-api-gaura.devpackgroup.com/api/v1/apartment/',
      {
        headers: {
          // "Authorization": "Token " + this.userToken,
        }
      }
    ).then(function (response) {
      setApartamentos(response.data)
    }.bind(this)).catch(function (error) {
      //  this.handleError(error, 'clients');
    }.bind(this));
  }
  const { width, height } = useWindowDimensions(); //width <= 768
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedAparment, setSelectedAparment] = useState(apartamentos[0]);

  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');

  const [ventaORenta, setVentaORenta] = useState();
  const [amueblado, setAmueblado] = useState();

  const [comments, setComments] = useState('');

  const setNameValidate = (value) => {
    if (!/[0-9]$/.test(value)) {
      setName(value)
    }
  }

  const nameRegex = RegExp(
    /^[A-Za-z]{3,}/
  )

  const emailRegex = RegExp(
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
  );

  const phoneRegex = RegExp(
    /^[0-9]{8}/
  )


  const ApartmentCard = (props) => {
    const {
      image,
      apt_name,
      metraje,
      rooms,
      bathrooms,
      setValue,
      onClick
    } = props;



    const [carouselValue, setCarouselValue] = useState(0);
    //let show= OpenView(false)
    return (

      <div className='col-6 col-md-3  my-apartment-card-container'>
        <div className="card my-apartment-card h-100 w-100 m-0">
          <div className="card-img-top apartment-card-carousel-container" alt="...">
            <div className='image-aparment-card'>
              <img src={image} alt='' />
            </div>
          </div>
          <div className="card-body pb-0 my-apartment-card-body text-center">
            <h4 className="card-title mb-md-3 mt-md-3">{apt_name}</h4>

            <div className='row g-0 row-margin justify-content-center'>
              <div className='icon-atribute-container'>
                <img alt='' src={mts} className='apartment-info-icon-mts' />
                {/* <div className='de'> */}
                <p>{metraje} METRAJE</p>
                {/* </div> */}
              </div>
            </div>
            <div className='row g-0 row-margin justify-content-center'>
              <div className='icon-atribute-container'>
                <img alt='' src={roomsA} className='apartment-info-icon-rooms' />
                <p>{rooms} {rooms > 1 ? 'HABITACIONES': 'HABITACIÓN'}</p>
              </div>
            </div>
            <div className='row g-0 justify-content-center'>
              <div className='icon-atribute-container'>
                <img alt='' src={bathroomsimg} className='apartment-info-icon-bathrooms' />
                {/* <div className='col description text-start restrooms'> */}
                <p className="">{bathrooms} {bathrooms >1 ? 'BAÑOS' :'BAÑO'} </p>
                {/* </div> */}
              </div>
            </div>

            <p className="more-info-apartment-card" onClick={() => onClick()}
            //onClick={() => setValue(true)} 
            >
              MÁS INFORMACIÓN <img src={plus} alt='' />
            </p>
          </div>
        </div>
      </div>

    )
  }


  const Aptmodal = (props) => {
    const {
      modalIsOpen,
      afterOpenModal,
      closeModal,
    } = props;
    const { width } = useWindowDimensions();

    return (

      <Modal
        isOpen={modalIsOpen}
        closeTimeoutMS={500}
        // isOpen={true}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={width <= 450 ? modalStylesMobile : modalStyles}
        contentLabel="Example Modal"
      >
        <button
          className='btn-close closemodal-apart'
          type="button"
          align-self-end
          // class="btn-close"
          onClick={() => closeModal()} />

        <Detail {...props} selectedAparment={selectedAparment} />
        <div className="popup-footer-apt" id="popup-footer">
          <Footer />
        </div>
      </Modal>
    )
  }
  const [isSending, setIsSending] = useState(false);
  const [validated, setValidated] = useState(false);
  const fakeSend = () => {
    if (
      nameRegex.test(name) &&
      emailRegex.test(email) &&
      phoneRegex.test(phone) &&
      ventaORenta &&
      amueblado
    ) {
      setIsSending(true)
      setTimeout(() => {
        setIsSending(false);
        setValidated(true);
        setTimeout(() => setValidated(false), 2000)
      }, 3000)
    } else {
      alert('Por favor ingrese todos los campos')
    }
  };
  const {
    setValue,
  } = props;
  return (
    <div className='container-fluid pt-0 align-items-center g-0 my-container-apartments-general'>
      <Aptmodal modalIsOpen={modalOpen} closeModal={() => setModalOpen(false)} />

      <img src={circle0} alt="" className='circle0-apartments' />
      <div className='row g-0'>
        <div className='col g-0 awesome-place'>
          <h1>LIVE IN AN<br />AWESOME PLACE</h1>
        </div>
        <div className='col-12 col-md-7 g-0 pb-1 d-flex flex-column justify-content-between'>
          <div className='row-5  g-0 random-box-apartments' />
          <div className='
            row
            mb-4
            g-0
            align-items-center
            offset-lg-2
            apartments-filters'>
            <div className='filters col-4 col-lg-3'>
              <div className='filters-text '>
                HABITACIONES
              </div>
              <div className='col-2 col-sm-2 g-0'>
                <select className='select-top'>
                  <option selected disabled hidden value=""></option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                </select>
              </div>
            </div>
            <div className='filters col-4 col-lg-3'>
              <div className='filters-text text-left ps-md-2'>
                COMPRA-RENTA
              </div>
              <div className='col-2 col-sm-2 g-0'>
                <select className='select-top'>
                  <option selected disabled hidden value=""></option>
                  <option value="1">COMPRA</option>
                  <option value="2">RENTA</option>
                </select>
              </div>
            </div>
            <div className='filters col-4 col-lg-3'>
              <div className='filters-text text-left ps-md-2'>
                AMUEBLADO
              </div>
              <div className='col-2 col-sm-2 g-0'>
                <select className='select-top select-top-last'>
                  <option selected disabled hidden value=""></option>
                  <option value="1">SI</option>
                  <option value="2">NO</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row 
      justify-content-center
      
      '>
        {
          apartamentos.map((value, key) => {
            return (
              <ApartmentCard key={key} onClick={() => {
                console.log(value)
                setSelectedAparment(value);
                setModalOpen(true);
              }}
                {...{ ...value, setValue }} />
            );
          })
        }

        <div className='d-none d-lg-block row-5  g-0 random-line' />
      </div>
      <img src={circle0} alt="" className='circle1-apartments' />
      <img src={circle0} alt="" className='circle2-apartments' />
    </div>
  )
}

export default ApartmentsGeneral;