import React, { useState, useEffect } from "react";
import circle0 from '../../../assets/images/quospaces/circle.svg'
import './styles.scss';
import whatsapp from '../../../assets/images/quospaces/whatsapp.svg';
import Slider from "react-slick";
import p01 from '../../../assets/images/Workspaces/carousel/01.jpg';
import p02 from '../../../assets/images/Workspaces/carousel/02.jpg';
import p03 from '../../../assets/images/Workspaces/carousel/03.jpg';
import p04 from '../../../assets/images/Workspaces/carousel/04.jpg';
import p05 from '../../../assets/images/Workspaces/carousel/05.jpg';
import p06 from '../../../assets/images/Workspaces/carousel/06.jpg';
import axios from 'axios';
const data = {
    name: "RSA",
    images: [
        p01,
        p02,
        p03,
        p04,
        p05,
        p06
    ]
  }
  
const QuoWorkspacesDetail =  () => {
    const [isSending, setIsSending] = useState(false);
    const [validated, setValidated] = useState(false);
    
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [ventaORenta, setVentaORenta] = useState();
    const [min, setMin] = useState('');
    const [max, setMax] = useState('');
    const [comments, setComments] = useState('');
    const setNameValidate = (value) => {
        if (!/[0-9]$/.test(value)) {
        setName(value)
        }
    }
    
    const nameRegex = RegExp(
        /^[A-Za-z]{3,}/
    ) 

    const emailRegex = RegExp(
        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    );
    
    const phoneRegex = RegExp(
        /^[0-9]{8}/
    )
    
  const fakeSend = () => {
    if (
        nameRegex.test(name) &&
        emailRegex.test(email) &&
        phoneRegex.test(phone) &&
        ventaORenta &&
        min &&
        max
    ) {
        workspacesForm(name,phone,email,ventaORenta,min,max,comments)
      setIsSending(true)
      setTimeout(() => {
        setIsSending(false);
        setValidated(true);
        setTimeout(() => setValidated(false), 2000)
      }, 3000)
    } else {
      alert('Por favor ingrese todos los campos')
    }
  };

  const workspacesForm = (name, mail, phone,compra,min,max, comment) => {
    const formData = new FormData();
    name && formData.append("name", name );
    mail && formData.append("email", mail);
    phone && formData.append("phone", phone);
    compra && formData.append("rent", compra);
    min && max && formData.append("length", `${min},${max}`);
    comment && formData.append("comments", comment);
    formData.append("form", "3");
    axios.post(
      'https://api-ecommerce.devpackgroup.com/api/v1/send_email_quo/',
      formData,
      {
        headers: {
          // "Authorization": "Token " + this.userToken,
        }
      }
    ).then(function (response) {

    }.bind(this)).catch(function (error) {

    }.bind(this));
  }


    const settings = {
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false,
      };
    return (
        <div className="container-fluid my-container-workspaces-detail">
        <div className="row quo-workspaces-row-1 g-0">
            <div className="col-12 col-lg-5 justify-content-center d-flex flex-column info-col">
                <img className="random-circle-0" src={circle0} alt="" />
                <div className="random-box-0" />
                <p className="info-text">
                    <strong>WORKSPACES</strong><br></br>
                     La ubicación privilegiada de QUO lo convierte en el destino perfecto para todo tipo de negocios, ya que la zona 4 es una de las más céntricas y transitadas de la ciudad. Es también favorecida por ser una de áreas de mayor deseo de trabajar, especialmente en los jóvenes profesionales.
                </p>
                <div className="phone">
                    <img src={whatsapp} alt="" />
                    <p>
                        (+502) 5966-1111
                    </p>
                </div>
            </div>
            <div className="col-12 col-lg-7 " style={{paddingBottom: '5vh'}}>
                    <div className='form-quo-workspaces'>
                        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"/>
                        <div className='row'>
                            <div className='col  '>
                                <input
                                    type="text"
                                    className="input-name"
                                    placeholder="NOMBRE"
                                    value={name}
                                    onChange={(e) => setNameValidate(e.target.value)}
                                    style={name && !nameRegex.test(name) ? {border: '2px solid #CD405C'} : {border: '2px solid #11bbbb'}}
                                />
                            </div>
                            <div className='col'>
                                <input
                                    type="number"
                                    className="input-name"
                                    placeholder="TELÉFONO"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    style={phone && !phoneRegex.test(phone) ? {border: '2px solid #CD405C'} : {border: '2px solid #11bbbb'}}
                                />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col '>
                                <input
                                    type="text"
                                    className="input-email"
                                    placeholder="CORREO ELECTRÓNICO"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    style={email && !emailRegex.test(email) ? { border: '2px solid #CD405C' } : { border: '2px solid #11bbbb' }}
                                />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col select-venta'>
                                <select className='select'
                                    value={ventaORenta ? ventaORenta : ''}
                                    onChange={(e) => setVentaORenta(e.target.value)}
                                    placeholder='VENTA O RENTA'>
                                    <option selected disabled hidden value="">COMPRA O VENTA</option>
                                    <option value="Renta">Renta</option>
                                    <option value="Compra">Compra</option>
                                </select>
                            </div>
                            <div className='col select-mueble'>
                                <div className="row justify-content-start pe-2">
                                    <p className="meters-text">METRAJE OFICINA</p>
                                    <input
                                        type="number"
                                        className="input-meters input-min"
                                        placeholder="MÍN"
                                        value={min}
                                        onChange={(e) => setMin(e.target.value)}
                                    />
                                    <input
                                        type="number"
                                        className="input-meters"
                                        placeholder="MÁX"
                                        value={max}
                                        onChange={(e) => setMax(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col'>
                                <textarea
                                    rows="3"
                                    cols="50"
                                    placeholder="COMENTARIOS"
                                    className=" textarea-form"
                                    value={comments}
                                    onChange={(e) => setComments(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col '>
                                <button
                                    id="button"
                                    type="button"
                                    onClick={() => fakeSend()}
                                    className={`${isSending ? 'onclic-apts' : 'something-apts'} ${validated ? 'validate-apts' : 'something-apts'} `}
                                />

                            </div>
                        </div>
                    </div>
            </div>
            <div className='row g-0'>
        <div className='col-12 offset-0 d-lg-block col-lg-8 offset-lg-2'>
          <div className='carouselWork'>
            <Slider {...settings}>
              {data.images && data.images.length !== 0 && data.images.map((value, index) => (
                <div style={{
                    width: "500px",
                    height: '100%'
                  //  display: this.state.display ? "block" : "none"
                  }}>

                  <img src={value} className='image-apt ' alt='...' />
                </div>
              ))}
            </Slider>

          </div>

        </div>
        </div>
      
        </div>
        </div>
    );
}

export default QuoWorkspacesDetail;