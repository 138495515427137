import React, {useState} from 'react';
import Select from 'react-select';
import axios from 'axios';
// import ScrollAnimation from 'react-animate-on-scroll';
import './styles.scss';
import circulo from '../../../assets/images/Form/upper.svg'

// import imageForm from '../../../assets/images/QUO-EDIFICIO-ZONA4.jpg';
import c1 from '../../../assets/images/Form/below.png'
import quo from '../../../assets/images/Contacto/quo.svg'
import quomunidad from '../../../assets/images/Contacto/QUOMUNIDAD.svg'

const selectOptions = [
  {
    value: 1,
    label: 'APARTAMENTOS'
  },
  {
    value: 2,
    label: 'WORKSPACES'
  },
  {
    value: 3,
    label: 'COMERCIO'
  },
  {
    value: 4,
    label: 'GASTRONOMÍA'
  },
  {
    value: 5,
    label: 'ESPACIOS QUO'
  },
  {
    value: 6,
    label: 'GALERÍA DE ARTE'
  },
]

const Formulario = (props) => {
  const {
    isHome,
    isApts,
    isContact,
    selectedValue,
  } = props;
  const styles = 'row my-row-form';
  const [isSending, setIsSending] = useState(false);
  const [validated, setValidated] = useState(false);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [area, setArea] = useState(selectedValue);
  const [comments, setComments] = useState('');
  const setNameValidate = (value) => {
    if (!/[0-9]$/.test(value)) {
      setName(value)
    }
  }
  
  const nameRegex = RegExp(
    /^[A-Za-z]{3,}/
    ) 

    const emailRegex = RegExp(
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
  );
  
  const phoneRegex = RegExp(
    /^[0-9]{8}/
    )
    
  const fakeSend = () => {
    if (
      nameRegex.test(name) &&
      emailRegex.test(email) &&
      phoneRegex.test(phone) &&
      area
    ) {
      setIsSending(true)
      contactForm(name,email,phone,area,comments)
      setTimeout(() => {
        setIsSending(false);
        setValidated(true);
        setTimeout(() => setValidated(false), 2000)
      }, 3000)
    } else {
      alert('Por favor ingrese todos los campos')
    }
  };

  const contactForm = (name, mail, phone, area, comment) => {
    const formData = new FormData();
    name && formData.append("name", name );
    mail && formData.append("email", mail);
    phone && formData.append("phone", phone);
    area && formData.append("option", selectOptions[area-1].label);
    comment && formData.append("comments", comment);
    formData.append("form", "1");
    axios.post(
      'https://api-ecommerce.devpackgroup.com/api/v1/send_email_quo/',
      formData,
      {
        headers: {
          // "Authorization": "Token " + this.userToken,
        }
      }
    ).then(function (response) {

    }.bind(this)).catch(function (error) {

    }.bind(this));
  }

 


  return (
      <div className="blue-col">
        <div className="random-box-form" />
        <div className="row offset-10 ">
          <div className="col d-block d-md-none g-0">
            <img src={circulo} className="circle1 g-0" alt="" />
          </div>
        </div>
        <div className="row upper-title g-0 offset-2 offset-lg-4">
          <div >
            <img className='quo-munity' src={quomunidad} alt='quo'/>
          </div>
          {/* <img  alt="" src={c0} />   */}
        </div>
        <div className={styles}>
          <div className="col col-lg col-6">
            <input
              type="text"
              className="form-control my-input-form name"
              placeholder="NOMBRE"
              value={name}
              onChange={(e) => setNameValidate(e.target.value)}
              style={name && !nameRegex.test(name) ? {border: '2px solid #CD405C'} : {border: '0'}}
            />
          </div>
          <div className="col col-lg col-6">
            <input
              type="number"
              className="form-control my-input-form"
              placeholder="TELÉFONO"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              style={phone && !phoneRegex.test(phone) ? {border: '2px solid #CD405C'} : {border: '0'}}
            />
          </div>
        </div>
        <div className={styles}>
          <div className="col">
          <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"/>
            <input
              type="email"
              className="form-control my-input-form"
              placeholder="CORREO ELECTRÓNICO"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={email && !emailRegex.test(email) ? { border: '2px solid #CD405C' } : { border: '0' }}
            />
          </div>
        </div>
        {(isHome || isContact) && (
          <div className={styles}>
            <div className="col">
              <select
                className="form-select-interest"
                value={area>0 ? area : undefined}
                onChange={(e) => setArea(e.target.value) } 
              >
              <option hidden disabled selected className="placeholder-select">ÁREA DE INTERÉS</option>
                {
                  selectOptions.map((option) => (
                    <option value={option.value} selected={option.value===selectedValue}>{option.label}</option>
                  ))
                }
              </select>
            </div>
            {isApts && 
              <div className="col-sm">
                <input
                  type="number"
                  className="form-control my-input-form"
                  placeholder="METRAJE"
                />
              </div>
            }
          </div>
        )}
      <div className={styles + ' my-button-container'}>
          <div className="col">
            <textarea
              rows="3"
              cols="50"
              placeholder="COMENTARIOS"
              className="form-control my-textarea"
              value={comments}
              onChange={(e) => setComments(e.target.value)}
            />
          </div>
        </div>
        {/*
    <div className={styles+' my-button-container'}>
          <div className="col">
            <div class="form-check form-horizontal">
              <input class="form-check-input my-form-check" type="checkbox" value="" id="flexCheckDefault" />
              <p className="p-label">
                ACEPTO RECIBIR COMUNICACIÓN, NOTICIAS E INFORMACIÓN DE GRUPO ROSUL
              </p>
            </div>
          </div>
        </div>
        */}
    
        <div className="col col-lg-3 offset-lg-5 last-row g-0 d-flex justify-content-center">
          <div className='my-button-container-send'>
            <button
              id="button"
              type="button"
              onClick={() => fakeSend()}
            className={`${isSending ? 'onclic' : 'something'} ${validated ? 'validate' : 'something'} `} />
          </div>
        </div>
        
        <div className="col-3 offset-1 g-0 d-none d-md-block align-self-end">
          <img alt="" className="end-circle" src={c1} />
        </div>
      </div>
  )
}

export default Formulario;
