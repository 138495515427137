
import rosaalacran from '../../assets/images/comercio/mapas/Mapas1/RosaAlacran.svg'
import opticalcenter from '../../assets/images/comercio/mapas/Mapas1/OpticalCenter.svg'
import petspaw from '../../assets/images/comercio/mapas/Mapas1/PetsPaw.svg'
import rosul from '../../assets/images/comercio/mapas/Mapas1/GrupoRosul.svg'
import redzebra from '../../assets/images/comercio/mapas/Mapas1/RedZebra.svg'
import whiskyden from '../../assets/images/comercio/mapas/Mapas1/TheWhiskyDen.svg'
import senssamap from '../../assets/images/comercio/mapas/Mapas1/SenssaStudio.svg'
import glad from '../../assets/images/comercio/mapas/Mapas1/Glad.svg'
import meridiano from '../../assets/images/comercio/mapas/Mapas1/Meridiano.svg'
import dudespizza from '../../assets/images/comercio/mapas/Mapas1/DudesPizzeria.svg'
import ol from '../../assets/images/comercio/mapas/Mapas1/OL.svg'
import frsh from '../../assets/images/comercio/mapas/Mapas1/FRSH.svg'
import simons from '../../assets/images/comercio/mapas/Mapas1/Simons.svg'
import smartfit from '../../assets/images/comercio/mapas/Mapas1/Smartfit.svg'
import gnc from '../../assets/images/comercio/mapas/Mapas1/GNCLiveWell.svg'
import max from '../../assets/images/comercio/mapas/Mapas1/MAX.svg'
import latorre from '../../assets/images/comercio/mapas/Mapas1/LaTorre.svg'
import labeaute from '../../assets/images/comercio/mapas/Mapas1/LaBeaute.svg'
import teporinosmapa from '../../assets/images/comercio/mapas/Mapas1/Teporingos.svg'
import galeriamapa from '../../assets/images/comercio/mapas/Mapas1/galeria.svg'
import haciendoartemapa from '../../assets/images/comercio/mapas/Mapas1/1-Haciendo Arte Galeria.svg'
import wichonmapa from '../../assets/images/comercio/mapas/Mapas1/1-San. Wichon.svg'
import aidamapa from '../../assets/images/comercio/mapas/Mapas1/1-AIDA.svg'


import level_one from '../../assets/icons/comercio/level1.svg';
import level_two from '../../assets/icons/comercio/level2.svg';
import level_pb from '../../assets/icons/comercio/PB.svg';

import alacran from '../../assets/images/comercio/logos/rosa_alacran.svg';
import smartFitLogo from '../../assets/images/comercio/logos/SmartFit.svg';
import simonsLogo from '../../assets/images/comercio/logos/Simons.svg';
import rosulLogo from '../../assets/images/comercio/logos/Rosul.svg';
import dudesPizza from '../../assets/images/comercio/logos/DudesPizzeria.svg';
import teporinoslogo from '../../assets/images/comercio/logos/Teporingos.svg';
import galerialogo from '../../assets/images/comercio/logos/galeria.svg';

import frshLogo from '../../assets/images/comercio/logos/FRSH.svg'
import gncLogo from '../../assets/images/comercio/logos/GNC.svg'
import laTorreLogo from '../../assets/images/comercio/logos/La Torre.svg'
import maxLogo from '../../assets/images/comercio/logos/MAX.svg'
import meridianoLogo from '../../assets/images/comercio/logos/Meridiano.svg'
import olLogo from '../../assets/images/comercio/logos/OL.svg'
import OpticalLogo from '../../assets/images/comercio/logos/OpticalCenter.svg'
import petsPawLogo from '../../assets/images/comercio/logos/PetsPaw.svg'
import redZebraLogo from '../../assets/images/comercio/logos/Red Zebra.svg'
import senssaLogo from '../../assets/images/comercio/logos/Senssa.svg'
import whiskyLogo from '../../assets/images/comercio/logos/Whisky Den.svg'
import beauteLogo from '../../assets/images/comercio/logos/La Beaute.svg'
import gladLogo from '../../assets/images/comercio/logos/GLAD.svg'
import bunsLogo from '../../assets/images/comercio/logos/BunsBunny.svg'
import haciendoarteLogo from '../../assets/images/comercio/logos/HaciendoArte.svg'
import wichonLogo from '../../assets/images/comercio/logos/SanWichon.svg'
import aidaLogo from '../../assets/images/comercio/logos/Aida.svg'


// IMAGENES DE CAROUSEL DE MODAL

import LeBeauteInterior from '../../assets/images/comercio/popups/01 - Le Beaute - Interior.jpg'
import LeBeauteInterior2 from '../../assets/images/comercio/popups/01 - Le Beaute - Interior2.jpg'
import LeBeautePortada from '../../assets/images/comercio/popups/01 - Le Beaute - Portada.png'
import MAXInterior from '../../assets/images/comercio/popups/02 - MAX - Interior.png'
import MAXPortada from '../../assets/images/comercio/popups/02 - MAX - Portada.png'
import MAXProducto from '../../assets/images/comercio/popups/02 - MAX - Producto.jpg'
import LaTorreInterior from '../../assets/images/comercio/popups/03 - La Torre - Interior.png'
import LaTorrePortada from '../../assets/images/comercio/popups/03 - La Torre - Portada.png'
import LaTorreProducto from '../../assets/images/comercio/popups/03 - La Torre - Producto.png'
import RosaInterior from '../../assets/images/comercio/popups/04 - Rosa Alacran - Interior.png'
import RosaPortada from '../../assets/images/comercio/popups/04 - Rosa Alacran - Portada.png'
import RosaProducto from '../../assets/images/comercio/popups/04RosaProducto.jpg'
import OpticalInterior from '../../assets/images/comercio/popups/05 - Optical Center - Interior.png'
import OpticalPortada from '../../assets/images/comercio/popups/05 - Optical Center - Portada.jpg'
import OpticalProducto from '../../assets/images/comercio/popups/05 - Optical Center - Producto.jpg'
import PetsPawinterior from '../../assets/images/comercio/popups/06-PetsPaw (1).jpg'
import PetsPawPortada from '../../assets/images/comercio/popups/06-PetsPaw (2).jpg'
import PetsPawProducto from '../../assets/images/comercio/popups/06-PetsPaw (3).jpg'
import DudesInterior from '../../assets/images/comercio/popups/07 - Dudes Pizzeria - Interior.jpg'
import DudesPortada from '../../assets/images/comercio/popups/07 - Dudes Pizzeria - Portada.jpg'
import DudesProducto from '../../assets/images/comercio/popups/07 - Dudes Pizzeria - Producto.jpg'
import FrshInterior from '../../assets/images/comercio/popups/08-FRSH (1).jpg'
import FrshPortada from '../../assets/images/comercio/popups/08-FRSH (2).jpg'
import FrshProducto from '../../assets/images/comercio/popups/08-FRSH (3).jpg'
import OLInterior from '../../assets/images/comercio/popups/09 - OL - Interior.jpg'
import OLPortada from '../../assets/images/comercio/popups/09 - OL - Portada.png'
import OLProducto from '../../assets/images/comercio/popups/09 - OL - Producto.jpg'
import SimonsInterior from '../../assets/images/comercio/popups/10-Simons (1).jpg'
import SimonsPortada from '../../assets/images/comercio/popups/10-Simons (2).jpg'
import Simonsproducto from '../../assets/images/comercio/popups/10-Simons (3).jpg'
import RedInterior from '../../assets/images/comercio/popups/11-RedZebra (1).jpg'
import RedPortada from '../../assets/images/comercio/popups/11-RedZebra (2).jpg'
import RedProducto from '../../assets/images/comercio/popups/11-RedZebra (3).jpg'
import WhiskyInterior from '../../assets/images/comercio/popups/12-WhiskyDen (1).jpg'
import WhiskyPortada from '../../assets/images/comercio/popups/12-WhiskyDen (2).jpg'
import WhiskyProducto from '../../assets/images/comercio/popups/12-WhiskyDen (3).jpg'
import SenssaInterior from '../../assets/images/comercio/popups/13-Senssa (1).jpg'
import SenssaPortada from '../../assets/images/comercio/popups/13-Senssa (2).jpg'
import SenssaProductos from '../../assets/images/comercio/popups/13-Senssa (3).jpg'
import SenssaProductos2 from '../../assets/images/comercio/popups/13-Senssa (4).jpg'
import SenssaProductos3 from '../../assets/images/comercio/popups/13-Senssa (5).jpg'
import SmartFitInterior from '../../assets/images/comercio/popups/14 - SmartFit - Interior.jpg'
import SmartFitPortada from '../../assets/images/comercio/popups/14 - SmartFit - Portada.jpg'
import SmartFitPortada2 from '../../assets/images/comercio/popups/Smartfit3.jpg'
import RosulA from '../../assets/images/comercio/popups/15 - Rosul - A.jpg'
import RosulB from '../../assets/images/comercio/popups/15 - Rosul - B.jpg'
import RosulC from '../../assets/images/comercio/popups/15 - Rosul - C.jpg'
import GNCA from '../../assets/images/comercio/popups/16-GNC-1.jpg'
import GNCB from '../../assets/images/comercio/popups/16-GNC-2.jpg'
import GNCC from '../../assets/images/comercio/popups/16-GNC-3.jpg'
import MeridianoA from '../../assets/images/comercio/popups/17-Meridiano-1.jpg'
import MeridianoB from '../../assets/images/comercio/popups/17-Meridiano-2.jpg'
import MeridianoC from '../../assets/images/comercio/popups/17-Meridiano-3.jpg'
import galeria1 from '../../assets/images/comercio/popups/galeria1.jpg'
import galeria2 from '../../assets/images/comercio/popups/galeria2.jpg'
import galeria3 from '../../assets/images/comercio/popups/galeria3.jpg'
import glad1 from '../../assets/images/comercio/popups/glad.png'
import glad2 from '../../assets/images/comercio/popups/glad2.png'
import glad3 from '../../assets/images/comercio/popups/glad3.png'
import buns1 from '../../assets/images/comercio/popups/BUNSBUNNY/01.jpg'
import buns2 from '../../assets/images/comercio/popups/BUNSBUNNY/02.png'
import buns3 from '../../assets/images/comercio/popups/BUNSBUNNY/03.jpg'
import teporingos1 from '../../assets/images/comercio/popups/01-Teporingos.png'
import teporingos2 from '../../assets/images/comercio/popups/02-Teporingos.png'
import teporingos3 from '../../assets/images/comercio/popups/03-Teporingos.png'
import cruzverde from '../../assets/images/comercio/mapas/Mapas1/cruzverde.svg'
import cruzverdeLogo from '../../assets/images/comercio/logos/cruzverde.svg'
import cruzverde1 from '../../assets/images/comercio/popups/cruzverde1.png'
import cruzverde2 from '../../assets/images/comercio/popups/cruzverde2.jpg'
import cruzverde3 from '../../assets/images/comercio/popups/cruzverde3.jpg'
import vinarium from '../../assets/images/comercio/mapas/Mapas1/Vinarium.svg'
import vinariumLogo from '../../assets/images/comercio/logos/Vinarium.svg'
import vinarium1 from '../../assets/images/comercio/popups/Vinarium1.png'
import vinarium2 from '../../assets/images/comercio/popups/Vinarium2.png'
import vinarium3 from '../../assets/images/comercio/popups/Vinarium3.png'
import LM from '../../assets/images/comercio/mapas/Mapas1/lastminute.svg'
import LMLogo from '../../assets/images/comercio/logos/Lastminute.svg'
import LM1 from '../../assets/images/comercio/popups/LM1.jpg'
import LM2 from '../../assets/images/comercio/popups/LM2.jpg'
import LM3 from '../../assets/images/comercio/popups/LM3.jpg'
import Cantel from '../../assets/images/comercio/mapas/Mapas1/Cantel.svg'
import CantelLogo from '../../assets/images/comercio/logos/CANTEL.svg'
import Cantel1 from '../../assets/images/comercio/popups/Cantel1.jpg'
import Cantel2 from '../../assets/images/comercio/popups/Cantel2.jpg'
import Cantel3 from '../../assets/images/comercio/popups/Cantel3.jpg'
import HaciendoArte1 from '../../assets/images/comercio/popups/Haciendoarte1.jpg'
import HaciendoArte2 from '../../assets/images/comercio/popups/Haciendoarte2.jpg'
import HaciendoArte3 from '../../assets/images/comercio/popups/Haciendoarte3.jpg'

import WICHON1 from '../../assets/images/comercio/popups/Sanwichon_01.jpg'
import WICHON2 from '../../assets/images/comercio/popups/Sanwichon_02.jpg'
import WICHON3 from '../../assets/images/comercio/popups/Sanwichon_03.jpg'
import WICHON4 from '../../assets/images/comercio/popups/Sanwichon_04.jpg'
import WICHON5 from '../../assets/images/comercio/popups/Sanwichon_05.jpg'

import aida1 from '../../assets/images/comercio/popups/aida-01.jpg'
import aida2 from '../../assets/images/comercio/popups/aida-02.jpg'
import aida3 from '../../assets/images/comercio/popups/aida-03.jpg'
import aida4 from '../../assets/images/comercio/popups/aida-04.jpg'
import aida5 from '../../assets/images/comercio/popups/aida-05.jpg'
import aida6 from '../../assets/images/comercio/popups/aida-06.jpg'

import pecos1 from '../../assets/images/comercio/popups/pecos1.jpg'
import pecos2 from '../../assets/images/comercio/popups/pecos2.jpg'
import pecos3 from '../../assets/images/comercio/popups/pecos3.jpg'
import pecos4 from '../../assets/images/comercio/popups/pecos4.jpg'
import pecos5 from '../../assets/images/comercio/popups/pecos5.jpg'
import pecos6 from '../../assets/images/comercio/popups/pecos6.jpg'
import pecos7 from '../../assets/images/comercio/popups/pecos7.jpg'
import pecosLogo from '../../assets/images/comercio/logos/pecosLogo.jpg'


const negocios = [
  {
    name: 'LA TORRE',
    facebook: 'https://www.facebook.com/supermercadoslatorre',
    instagram: 'https://www.instagram.com/supermercadoslatorre/',
    otherLink: '',
    catalogo:'',
    type: 'SUPERMERCADO',
    location: 'PLANTA BAJA',
    map: latorre,
    logo: laTorreLogo,
    level: level_pb,
    images: [LaTorrePortada, LaTorreInterior, LaTorreProducto],
    days: ['LUNES A DOMINGO'],
    time: ['7:00 A 21:00 H'],
  },
  {
    name: 'RED ZEBRA',
    facebook: 'https://www.facebook.com/RedZebraBar',
    instagram: 'https://www.instagram.com/red_zebra_gt/?hl=es',
    otherLink: '',
    catalogo:'https://www.facebook.com/RedZebraBar/menu',
    type: 'RESTAURANTE | BAR',
    location: 'NIVEL 1',
    map: redzebra,
    logo: redZebraLogo,
    level: level_one,
    images: [RedPortada, RedInterior, RedProducto],
    days: ['LUNES A DOMINGO'],
    time: ['11:00 A 22:00 H'],
  },
  /* {
    name: 'ROSA ALACRÁN',
    facebook: 'https://www.facebook.com/rosaalacrangt',
    instagram: 'https://www.instagram.com/rosa.alacran',
    otherLink: '',
    catalogo:'',
    type: 'RESTAURANTE | BAR',
    location: 'NIVEL 1',
    map: rosaalacran,
    logo: alacran,
    level: level_one,
    images: [RosaPortada, RosaInterior, RosaProducto],
    days: ['MARTES A SÁBADO', 'DOMINGO','LUNES'],
    time: ['12:00 A 21:00 H', '12:00 A 19:00 H','CERRADO'],
  }, */
  {
    name: 'DUDES PIZZERÍA',
    facebook: 'https://www.facebook.com/dudespizzeria',
    instagram: 'https://www.instagram.com/dudes.pizzeria',
    otherLink: '',
    catalogo:'https://www.facebook.com/dudespizzeria/menu',
    type: 'RESTAURANTE',
    location: 'NIVEL 1',
    map: dudespizza,
    logo: dudesPizza,
    level: level_one,
    images: [DudesPortada, DudesInterior, DudesProducto],
    days: ['DOMINGO A MARTES', 'MIÉRCOLES A SÁBADO'],
    time: ['12:00 A 20:00 H', '12:00 A 21:00 H'],
  },
  {
    name: 'SMART FIT',
    facebook: 'https://www.facebook.com/Smartfitguatemala',
    instagram: 'https://www.instagram.com/smartfitgt',
    otherLink: '',
    catalogo:'',
    type: 'GIMNASIO',
    location: 'NIVEL 2',
    map: smartfit,
    logo: smartFitLogo,
    level: level_two,
    images: [SmartFitPortada, SmartFitInterior, SmartFitPortada2],
    days: ['LUNES A VIERNES ',' SÁBADO ',' DOMINGO'],
    time: ['4:30 A 22:30 H ',' 6:00 A 16:00 H ',' 7:00 A 14:00 H'],
  },
  {
    name: 'MAX',
    facebook: 'https://www.facebook.com/maxguate',
    instagram: 'https://www.instagram.com/tiendasmax/',
    otherLink: '',
    catalogo:'',
    type: 'ELECTRÓNICOS',
    location: 'PLANTA BAJA',
    map: max,
    logo: maxLogo,
    level: level_pb,
    images: [MAXPortada, MAXInterior, MAXProducto],
    days: ['LUNES A JUEVES', 'VIERNES A SÁBADO', 'DOMINGO'],
    time: ['7:00 A 21:00 H','10:00 A 21:00 H','7:00 A 21:00 H'],
  },
  {
    name: 'ROSUL',
    facebook: '',
    instagram: '',
    otherLink: 'https://gruporosul.com/',
    catalogo:'',
    type: 'INMOBILIARIA',
    location: 'NIVEL 1',
    map: rosul,
    logo: rosulLogo,
    level: level_one,
    images: [RosulA, RosulB, RosulC],
    days: ['LUNES A DOMINGO'],
    time: ['8:00 A 18:00 H'],
  },
  /* {
    name: 'SIMON\'S',
    facebook: '',
    instagram: 'https://www.instagram.com/simonsushigt',
    otherLink: '',
    catalogo:'https://www.instagram.com/simonsushigt/',
    type: 'RESTAURANTE',
    location: 'NIVEL 1',
    map: simons,
    logo: simonsLogo,
    level: level_one,
    images: [SimonsPortada, SimonsInterior, Simonsproducto],
    days: ['LUNES','MARTES','MIÉRCOLES A SÁBADO','DOMINGO','LUNES'],
    time: ['CERRADO','11:00 A 21:00 H','11:00 A 21:30 H','11:00 A 18:30 H',],
  }, */
  {
    name: 'MERIDIANO',
    facebook: '',
    instagram: 'https://www.instagram.com/meridiano.gt/?hl=es',
    otherLink: '',
    catalogo:'https://drive.google.com/file/d/11RexUN8nTZii4sgp2tMGhjpuESD5Nm7m/view?usp=sharing',
    type: 'RESTAURANTE/SHOP',
    location: 'NIVEL 1',
    map: meridiano,
    logo: meridianoLogo,
    level: level_one,
    images: [MeridianoA, MeridianoB, MeridianoC],
    days: ['LUNES A VIERNES', 'SÁBADO', 'DOMINGO'],
    time: ['7:00 A 19:00 H', '10:00 A 19:00 H', '10:00  17:00 H'],
  },
/*   {
    name: 'HACIENDO ARTE',
    facebook: 'https://www.facebook.com/haciendoartegt',
    instagram: '',
    otherLink: '',
    catalogo:'https://drive.google.com/file/d/11RexUN8nTZii4sgp2tMGhjpuESD5Nm7m/view?usp=sharing',
    type: 'GALERÍA DE ARTE',
    location: 'NIVEL 1',
    map: haciendoartemapa,
    logo: haciendoarteLogo,
    level: level_one,
    images: [HaciendoArte1, HaciendoArte2, HaciendoArte3],
    days: ['LUNES', 'MARTES A DOMINGO'],
    time: ['CERRADO', '10:00 A 18:00 H'],
  },  */
    {
    name: 'PECOS BILL',
    facebook: '',
    instagram: 'https://www.instagram.com/pecosbillgt/',
    otherLink: '',
    catalogo:'https://www.pecosbill.com.gt/menu/',
    type: 'RESTAURANTE',
    location: 'NIVEL 1',
    map: haciendoartemapa,
    logo: pecosLogo,
    level: level_one,
    images: [pecos1, pecos2, pecos3, pecos4,pecos5,pecos6,pecos7],
    days: ['LUNES A JUEVES', 'VIERNES', 'SÁBADO', 'DOMINGO', 'DELIVERY'],
    time: ['06:30 a 23:00 H', '07:00 A 24:00 H', '07:00 A 23:00 H','07:00 A 20:30 H'],
  },


  {
    name: 'GLAD',
    facebook: '',
    instagram: '',
    otherLink: '',
    catalogo:'',
    type: 'HELADERÍA',
    location: 'NIVEL 1',
    map: glad,
    logo: gladLogo,
    level: level_one,
    images: [glad1, glad2, glad3],
    days: ['LUNES A SÁBADO', 'DOMINGO'],
    time: ['11:00 A 19:00 H', '9:00 A 18:00 H'],
  },
  {
    name: 'GNC LIVE WELL',
    facebook: 'https://www.facebook.com/GNC.gt',
    instagram: 'https://www.instagram.com/gncguatemala',
    otherLink: '',
    catalogo:'https://gnc.com.gt/',
    type: 'SALUD GENERAL',
    location: 'PLANTA BAJA',
    map: gnc,
    logo: gncLogo,
    level: level_pb,
    images: [GNCA, GNCB, GNCC],
    days: ['LUNES A DOMINGO'],
    time: ['9:00 A 19:00 H'],
  },
  {
    name: 'PETSPAW',
    facebook: 'https://www.facebook.com/petspawgt',
    instagram: 'https://www.instagram.com/petspawgt',
    otherLink: '',
    catalogo:'',
    type: 'PET SHOP & GROOMING',
    location: 'NIVEL 1',
    map: petspaw,
    logo: petsPawLogo,
    level: level_one,
    images: [PetsPawPortada, PetsPawinterior, PetsPawProducto],
    days: ['LUNES A DOMINGO'],
    time: ['7:00 A 19:00 H'],
  },
  {
    name: 'FRSH',
    facebook: 'https://www.facebook.com/FRSH-102380371721536',
    instagram: 'https://www.instagram.com/frsh.coffee',
    otherLink: '',
    catalogo:'https://www.facebook.com/FRSH-102380371721536/menu/',
    type: 'CAFÉ',
    location: 'NIVEL 1',
    map: frsh,
    logo: frshLogo,
    level: level_one,
    images: [FrshPortada, FrshInterior, FrshProducto],
    days: ['LUNES A SÁBADO','DOMINGO'],
    time: ['8:00 A 20:00 H','8:00 A 18:00 H'],
  },
  {
    name: 'SENSSA STUDIO',
    facebook: 'https://www.facebook.com/senssastudio',
    instagram: 'https://www.instagram.com/senssastudio',
    otherLink: '',
    catalogo: 'https://drive.google.com/file/d/1SrJVq-mhFj9GF0Ce72ZN0e1ApB8eCmfQ/view?usp=sharing',
    type: 'SALÓN DE BELLEZA Y SPA',
    location: 'NIVEL 1',
    map: senssamap,
    logo: senssaLogo,
    level: level_one,
    images: [SenssaPortada, SenssaInterior, SenssaProductos, SenssaProductos2,SenssaProductos3],
    days: ['LUNES A VIERNES','SÁBADO','DOMINGO'],
    time: ['7:00 A 19:00 H','7:00 A 17:00 H','10:00 A 15:00 H'],
  },
  // {
  //   name: 'LA BEAUTE',
  //   facebook: '',
  //   instagram: '',
  //   otherLink: '',
  //   catalogo:'',
  //   type: 'ACCESORIOS',
  //   location: 'PLANTA BAJA',
  //   map: labeaute,
  //   logo: beauteLogo,
  //   level: level_pb,
  //   images: [LeBeautePortada, LeBeauteInterior, LeBeauteInterior2],
  //   days: ['LUNES A DOMINGO'],
  //   time: ['10:00 A 19:00 H'],
  // },
  /*{
    name: 'OPTICAL CENTER',
    facebook: 'https://www.facebook.com/OpticalCenterFT',
    instagram: '',
    otherLink: 'https://www.soyoptical.com/',
    catalogo:'',
    type: 'ÓPTICA',
    location: 'NIVEL 1',
    map: opticalcenter,
    logo: OpticalLogo,
    level: level_one,
    images: [OpticalPortada, OpticalInterior, OpticalProducto],
    days: ['LUNES A SÁBADO', 'DOMINGO'],
    time: ['10:00 A 18:30 H', 'CERRADO'],
  },*/
  {
    name: 'OL',
    facebook: '',
    instagram: 'https://www.instagram.com/centroculturalcervecero',
    otherLink: '',
    catalogo:'https://untappd.com/v/ol/9775780',
    type: 'CERVEZAS | CÓCTELES',
    location: 'NIVEL 1',
    map: ol,
    logo: olLogo,
    level: level_one,
    images: [OLPortada, OLInterior, OLProducto],
    days: ['LUNES A DOMINGO',],
    time: ['14:00 A 21:00 H',],
  },
  {
    name: 'THE WHISKY DEN',
    facebook: 'https://www.facebook.com/elwhiskyden',
    instagram: 'https://www.instagram.com/el_whisky_den',
    otherLink: '',
    catalogo:'',
    type: 'RESTAURANTE | BAR',
    location: 'NIVEL 1',
    map: whiskyden,
    logo: whiskyLogo,
    level: level_one,
    images: [WhiskyPortada, WhiskyInterior, WhiskyProducto],
    days: ['LUNES A DOMINGO'],
    time: ['13:00 A 21:00 H'],
  },
  {
    name: 'TEPORINGOS',
    facebook: 'https://www.facebook.com/teporingosgrill',
    instagram: 'https://www.instagram.com/teporingosgrill/',
    otherLink: 'https://teporingosgrill.com/index.html',
    catalogo:'https://teporingosgrill.com/menu.html',
    type: 'RESTAURANTE',
    location: 'NIVEL 1',
    map: teporinosmapa,
    logo: teporinoslogo,
    level: level_one,
    images: [teporingos1, teporingos2, teporingos3],
    days: ['LUNES MARTES Y DOMINGO','MIÉRCOLES JUEVES Y SÁBADO', 'VIERNES'],
    time: ['12:30 A 20:00 H','12:30 A 21:00 H','12:30 A 22:00 H'],
    //white: true,
  },
 /*  {
    name: 'GALERÍA DE ARTE',
    facebook: '',
    instagram: '',
    otherLink: '',
    catalogo:'',
    type: 'GALERÍA',
    location: 'NIVEL 1',
    map: galeriamapa,
    logo: galerialogo,
    level: level_one,
    images: [galeria1, galeria2, galeria3],
    days: ['LUNES A VIERNES', 'SÁBADO Y DOMINGO'],
    time: ['8:00 A 18:00 H', '8:00 A 17:00 H'],
  }, */
  /* {
    name: 'BUNS BUNNY',
    facebook: 'https://www.facebook.com/BunsBunnyguatemala',
    instagram: 'https://www.instagram.com/buns.bunnygt/',
    otherLink: '',
    catalogo:'',
    type: 'RESTAURANTE',
    location: 'NIVEL 1',
    map: opticalcenter,
    logo: bunsLogo,
    level: level_one,
    images: [buns1, buns2, buns3],
    days: ['LUNES A DOMINGO'],
    time: ['7:30H a 20:00H'],
  }, */
  {
    name: 'CRUZ VERDE',
    facebook: '',
    instagram: '',
    otherLink: 'tresfsrmacias.com',
    catalogo:'',
    type: 'FARMACIAS',
    location: 'PLANTA BAJA',
    map: cruzverde,
    logo: cruzverdeLogo,
    level: level_pb,
    images: [cruzverde1, cruzverde2, cruzverde3],
    days: ['LUNES A SÁBADO', 'DOMINGO'],
    time: ['9:00H a 19:00H', 'CERRADO'],
  },
  {
    name: 'VINARIUM',
    facebook: 'https://www.facebook.com/Vinariumgt',
    instagram: 'https://www.instagram.com/vinariumgt/',
    otherLink: '',
    catalogo:'',
    type: 'BAR',
    location: 'NIVEL 1',
    map: vinarium,
    logo: vinariumLogo,
    level: level_one,
    images: [vinarium1, vinarium2, vinarium3],
    days: ['LUNES A MIÉRCOLES', 'JUEVES A SÁBADO','DOMINGO'],
    time: ['14:00H a 22:00H','14:00H a 24:00H','14:00H  a 20:00H'],
  },
  {
    name: 'LAST MINUTE',
    facebook: 'https://www.facebook.com/lastminuteimpresion/?ref=page_internal.',
    instagram: 'https://www.instagram.com/lastminute.gt/',
    otherLink: '',
    catalogo:'',
    type: 'CENTRO DE IMPRESIÓN',
    location: 'PLANTA BAJA',
    map: LM,
    logo: LMLogo,
    level: level_pb,
    images: [LM1, LM2, LM3],
    days: ['LUNES A VIERNES', 'SÁBADO Y DOMINGO'],
    time: ['08:00H a 17:00H', 'CERRADO'],
  },
  {
    name: 'CANTEL',
    facebook: ' https://www.facebook.com/CantelGuatemala',
    instagram: '',
    otherLink: '',
    catalogo:'',
    type: 'ARTICULOS PARA EL HOGAR',
    location: 'PLANTA BAJA',
    map: Cantel,
    logo: CantelLogo,
    level: level_pb,
    images: [Cantel1, Cantel2, Cantel3],
    days: ['LUNES A DOMINGO'],
    time: ['09:00H a 17:00H'],
  },
  {
    name: 'SAN WICHON',
    facebook: 'https://www.facebook.com/search/top?q=petspaw',
    instagram: 'https://www.instagram.com/petspawgt/?hl=es',
    otherLink: '',
    catalogo:'https://www.sanwichon.com/es/_files/ugd/a4ba33_0a09fbfdbde94d68899936ae7cc7b208.pdf',
    type: 'RESTAURANTE',
    location: 'NIVEL 1',
    map: wichonmapa,
    logo: wichonLogo,
    level: level_one,
    images: [WICHON1, WICHON2, WICHON3, WICHON4,WICHON5],
    days: ['LUNES A MIÉRCOLES', 'JUEVES A SÁBADO'],
    time: ['12:00H a 20:30H', '12:00H a 21:30H'],
  },
  {
    name: 'AIDA',
    facebook: 'https://www.facebook.com/search/top?q=petspaw',
    instagram: 'https://www.instagram.com/petspawgt/?hl=es',
    otherLink: '',
    catalogo:'https://www.aida.gt/restaurante/menuz10/',
    type: 'RESTAURANTE',
    location: 'NIVEL 1',
    map: aidamapa,
    logo: aidaLogo,
    level: level_one,
    images: [aida1, aida2, aida3, aida4,aida5,aida6],
    days: ['LUNES A VIERNES', 'DOMINGO'],
    time: ['07:00H a 20:30H','07:00H a 18:00H'],
  },
]

export default negocios;
