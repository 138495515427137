import React, {useEffect, useState} from "react";
import './styles.scss';

import anime from 'animejs/lib/anime.es.js';

import ApartmentsDetail from './Detail'
import ApartmentsGeneral from './General'

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

const Apartments = (props) => {
  const [show, setValue] = useState(false);



  const { width } = useWindowDimensions()
 
  return (
    <div className='apartments-container' >
      <div className='apartments-box'>
        <div className='apartments-element' >
        
                <ApartmentsGeneral  {...props}  />
         
        </div>
     
      </div>
    </div>
  )
}

export default Apartments;